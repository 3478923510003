export const LANDING = '/';
export const HOME = '/home';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/account';
export const USERS = '/users';
export const PASSWORD_FORGET = '/pw-forget';
export const PASSWORD_RESET = '/auth/action';
export const VISITORS = '/visitors';
export const VISITOR_REGISTRATION = '/visitor-registration';
export const VISITOR_STATUS = '/visitor-status';
