const PURPOSE = {
  RESIDENT: 'Resident',
  VISITOR: 'Visitor',
  'FOOD DELIVERY': 'Food Delivery',
  'COURIER DELIVERY': 'Courier Delivery',
  'SERVICES/RENOVATION': 'Services/Renovation',
  OTHERS: 'Others',
};

export default PURPOSE;
