const PLACES = {
  UNIT: 'Unit',
  MANAGEMENT: 'Management Office',
  LAUNDRY: 'Laundry',
  CAFE: 'Cafe',
  MINIMARKET: 'Mini Market',
  TAILOR: 'Tailor',
  FUNCTIONHALL: 'Function Hall',
  OTHERS: 'Others',
};

export default PLACES;
