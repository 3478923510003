const UNITS = [
  '05-01',
  '05-02',
  '05-03',
  '05-04',
  '05-05',
  '05-06',
  '05-07',
  '05-08',
  '06-01',
  '06-02',
  '06-03',
  '06-04',
  '06-05',
  '06-06',
  '06-07',
  '06-08',
  '07-01',
  '07-02',
  '07-03',
  '07-04',
  '07-05',
  '07-06',
  '07-07',
  '07-08',
  '08-01',
  '08-02',
  '08-03',
  '08-04',
  '08-05',
  '08-06',
  '08-07',
  '08-08',
  '09-01',
  '09-02',
  '09-03',
  '09-04',
  '09-05',
  '09-06',
  '09-07',
  '09-08',
  '10-01',
  '10-02',
  '10-03',
  '10-04',
  '10-05',
  '10-06',
  '10-07',
  '10-08',
  '11-01',
  '11-02',
  '11-03',
  '11-04',
  '11-05',
  '11-06',
  '11-07',
  '11-08',
  '12-01',
  '12-02',
  '12-03',
  '12-04',
  '12-05',
  '12-06',
  '12-07',
  '12-08',
  '13-01',
  '13-02',
  '13-03',
  '13-04',
  '13-05',
  '13-06',
  '13-07',
  '13-08',
  '14-01',
  '14-02',
  '14-03',
  '14-04',
  '14-05',
  '14-06',
  '14-07',
  '14-08',
  '15-01',
  '15-02',
  '15-03',
  '15-04',
  '15-05',
  '15-06',
  '15-07',
  '15-08',
  '16-01',
  '16-02',
  '16-03',
  '16-04',
  '16-05',
  '16-06',
  '16-07',
  '16-08',
  '17-01',
  '17-02',
  '17-03',
  '17-04',
  '17-05',
  '17-06',
  '17-07',
  '17-08',
  '18-01',
  '18-02',
  '18-03',
  '18-04',
  '18-05',
  '18-06',
];

export default UNITS;
